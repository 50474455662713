html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, button,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
* {
	outline: none;
	transition: outline-color 0.7s ease-out;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	margin: 0;
	background: #a6e9ff;
}

* {
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none
}

i, em {
	font-style: italic;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	padding-top: 70px;
	background: #a6e9ff;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #a6e9ff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	transition: 0.5s;
  background: #3742fa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e67ff;
}

iframe{
	border-radius: 15px;
}

@font-face {
	font-family: 'Red Hat';
	src: url(./assets/fonts/RedHatDisplay-Medium.ttf);
}
@font-face {
	font-family: 'Lalezar';
	src: url(./assets/fonts/Lalezar-Regular.ttf);
}
